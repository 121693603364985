<template>
    <div class="profile_notes">
        <div class="title">
            <h3>Client Tracking</h3>
            <div class="right_side">
                <div class="search_panel">
                    <div class="form-field">
                        <input type="text" placeholder="Search" :disabled="progressTrackingLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                    </div>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <page-filter v-model="params.per_page" />
            </div>
        </div>
        <div class="loader_area3" v-if="progressTrackingLoader"><quote-loader/></div>
        <div class="notes_wpr" v-else >
            <ul class="card_listing" v-if="contactProgressTrackings.total">
                <li v-for="(progressTracking, p) of contactProgressTrackings.data" :key="p">
                    <profile-card v-bind:actions="actions" :action-handler="actionHandler" :selected-row="progressTracking">
                        <h4 class="mb-3 pointer" @click="viewProgress(progressTracking)"><img src="@/assets/images/client.svg">{{ progressTracking.progress_tracking.name }}:</h4>
                        <div class="card_content">
                            <h5>Submissions:</h5>
                            <!-- <p>{{ progressTracking.submission_count }} / {{ progressTracking.occurrences_count }}</p> -->
                            <p>{{ progressTracking.total_submitted }} / {{ progressTracking.total_availability }}</p>
                            <ul class="score">
                                <li>
                                    <h5>Participation Score:</h5>
                                    <p>{{progressTracking.participation_score}}</p>
                                </li>
                                <li>
                                    <h5>Progress Score:</h5>
                                    <p>{{progressTracking.progress_score}}</p>
                                </li>
                            </ul>
                        </div>
                        <div class="card_footer">
                            <h5>Assigned on:</h5>
                            <span class="date">
                                {{ moment.utc(progressTracking.created_at).local().format('ll | LT') }}
                                <span class="text-danger" v-if="progressTracking.deleted_at">[deleted]</span>
                            </span>
                        </div>
                    </profile-card>
                </li>
            </ul>
            <div class="empty_list" v-if="contactProgressTrackings.total == 0 && !progressTrackingLoader">
                <img src="@/assets/images/empty_state.svg">
                <h4>No Records Found!</h4>
            </div>
        </div>
        <div class="pagination" v-show="contactProgressTrackings.total">
            <pagination v-model="params.page" :pages="contactProgressTrackings.last_page" @update:modelValue="handlePagination" />
        </div>
        <div class="form_view" v-if="showProgress">
            <button class="close_btn pointer" @click="showProgress = false"><i class="fas fa-times"></i></button>
            <progress-report v-model="showProgress" :progress-tracking="selectedRelation" :contact-relation="contact" />
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const ProfileCard = defineAsyncComponent(() => import('@/pages/contact/components/profile/ProfileCard'))
    const ProgressReport = defineAsyncComponent(() => import('@/pages/progress-tracking/components/ProgressReport'))

    import moment from 'moment'

    export default {
        name: 'Tab Progress Tracking',

        data () {
            return {
                actions: [
                    {
                        icon: 'fa-search-plus',
                        label: 'View',
                        action: 'viewProgress'
                    },
                ],
                params: {
                    page: 1,
                    per_page: 6,
                    contact_id: '',
                    search: '',
                },
                moment,
                isTyping: false,
                showProgress: false,
                selectedRelation: {},
            };
        },

        props: {
            contact: Object,
        },

        components: {
            ProfileCard,
            ProgressReport,
        },

        watch: {
            'params.per_page' () {
                const vm = this;

                vm.getContactProgressTracking(vm.params);
            },

            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getContactProgressTracking(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getContactProgressTracking(vm.params);
                        }
                    }
                }
            },
        },

        computed: mapState({
            progressTrackingLoader: state => state.progressTrackingModule.contactProgressTrackingLoader,
            contactProgressTrackings: state => state.progressTrackingModule.contactProgressTrackings,
        }),

        mounted () {
            const vm = this;

            vm.params.contact_id = vm.contact.id;
            vm.getContactProgressTracking(vm.params);
        },

        methods: {
            ...mapActions({
                getContactProgressTracking: 'progressTrackingModule/getContactProgressTracking'
            }),

            actionHandler (progress, action) {
                const vm = this;

                vm[action](progress);
            },

            viewProgress (progress) {
                const vm = this;

                vm.showProgress = true;
                vm.selectedRelation = progress.progress_tracking ? progress.progress_tracking : {};
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getContactProgressTracking(vm.params);
            },
        },
    }
</script>

<style scoped>
    .notes_wpr{
        overflow-y: scroll;
        flex: 1;
    }
    .notes_wpr::-webkit-scrollbar {
        display: none;
    }
    .card_listing .score{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }
    .card_listing .score li{
        width: 50%;
        padding: 0 10px;
    }
    :deep(.form_view .loader_area.no_sidebar){
        position: static;
    }
    .profile_notes .form_view :deep(.title){
        margin-bottom: 0;
    }
    @media(max-width: 480px){
        .profile_notes .title{
            flex-wrap: wrap;
        }
        .profile_notes .title h3{
            width: 100%;
            margin-bottom: 15px;
        }
        .profile_notes .title .right_side{
            width: 100%;
        }
        .profile_notes .title .right_side .search_panel{
            margin: 0 auto 0 0;
        }
    }
</style>
